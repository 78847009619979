import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
export default () => {
  const { t } = useTranslation()
  /*
  const [loaded, setLoaded] = React.useState(false)
 
  React.useEffect(() => {
   
    loaded
      ? document.addEventListener(
          "animationstart",
          function(event) {
            if (event.animationName === "nodeInserted") {
              setTimeout(function() {
                document.querySelector(".loader").style.display = "none"
              }, 350)
            }
          },
          true
        )
      : setLoaded(true)
      
  }, [loaded])
*/
  React.useEffect(() => {
    const script = document.createElement("script")

    script.src = "https://embedsocial.com/embedscript/ei.js?v0.4"
    script.id = "EmbedSocialScript"
    document.getElementById("EmbedSocialScript") !== null &&
      document.getElementById("EmbedSocialScript").remove()
    document.getElementsByTagName("body")[0].appendChild(script)
  })
  return (
    <Layout className="page builds">
      <Helmet
        link={[
          {
            href:
              "https://embedsocial.com/cdn/embedsocial_lightbox_style.min.css?v0.4",
            id: "EmbedSocialLightboxCSS",
          },
        ]}
      />
      <SEO title={t("bikebuilds")} />

      <h1>
        <Trans t={t} i18nKey="bikebuilds" />
      </h1>
      <div className="-loader"></div>
      <div
        className="embedsocial-album"
        data-ref="f069f7ff2ff337ee9082d9b214edc3961f93b362"
      ></div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
